import config from '@/config/env-constants';
import _ from 'lodash';


export const AssetTypeUtil = {

    isDefaultCondition(condition) {
        return condition === 'good' || condition === 'damaged';
    },

    getDefaultConditions() {
        return [
            {
                id: 'good',
                condition: 'Good',
                description: 'Default',
                isActive: true
            },
            {
                id: 'damaged',
                condition: 'Damaged',
                description: 'Default',
                isActive: true
            }
        ]
    },

    getCodeName(prefix) {
        if (prefix && prefix.length > 0) {
            return prefix + '-Series';
        }
        return '';
    },

    getAssetType(allAssetTypesObj, assetCode) {
        let assetTypeObj = {};

        let customAssetTypes = _.filter(allAssetTypesObj, o => {
            return o.assetTagging === config.assetTagging.CUSTOM;
        });

        if (!_.isEmpty(customAssetTypes)) {
            for (const assetType of customAssetTypes) {
                let regex = new RegExp(assetType.regex);
                if (regex.test(assetCode)) {
                    assetTypeObj = assetType;
                    break;
                }
            }
        }

        return assetTypeObj;
    }

}